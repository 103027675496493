import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import { BouncingStyledBlackButton } from "../../components/Styled Components/Buttons";

// Assets
import CoachSmileGold from "../../static/images/Coach-Smile-Gold.svg";
// import ElectrifyzeLogo from "../../static/images/ElectrifyzeLogo-black.svg";
import iPadImage from "../../static/images/iPad-2-compressed.jpg";

const Screen1 = (props) => {
    const { handleButtonClick } = props;

    return (
        <>
            <Grid
                item
                container
                xs={12}
                sm={10}
                md={6}
                // sx={{ backgroundColor: "#ddd" }}
            >
                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 0, // Larger negative numbers move the layout up
                        marginBottom: 2, 
                    }}
                >
                    <img
                        // src={ElectrifyzeLogo}
                        // style={{ width: "300px" }}
                        src={CoachSmileGold}
                        style={{ height: "90px" }}
                        alt="Electrifyze Car Buying Coach"
                    />
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        marginTop: 1,
                        // backgroundColor: "#aaa",
                        paddingX: 5,
                    }}
                >
                    <Typography align="center" variant="body2">
                        By accelerating the transition to Electric Cars across
                        our entire community
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 2,
                    }}
                >
                    <Typography align="center" variant="h5">
                        We're creating
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        // backgroundColor: "#999",
                        marginTop: "20px",
                        paddingX: 7,
                    }}
                >
                    <Typography align="center" variant="h1">
                        <b>A Fossil Fuel Free LA County</b>
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        marginTop: 1,
                        // backgroundColor: "#aaa",
                        paddingX: 5,
                    }}
                >
                    <Typography align="center" variant="body1">
                        Electrifyze helps you
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 1,
                    }}
                >
                    <Typography align="center" variant="h5">
                        <b>become part of the solution</b>
                    </Typography>
                </Grid>

                <Box m={1} />

                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "106px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={iPadImage}
                            style={{ height: "90px" }}
                            alt="Electrifyze Car Buying Coach"
                        />
                    </Grid>
                    <Grid item >
                        <Typography variant="body1">
                            Sign up for a chance to
                        </Typography>
                        <Typography variant="h5">
                            <b>Win an Apple iPad</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item justifyContent="center">
                    <BouncingStyledBlackButton onClick={handleButtonClick}>
                        <Typography
                            variant="h4"
                            sx={{ marginY: 1, marginX: 10 }}
                        >
                            <b>Let's Do It</b>
                        </Typography>
                    </BouncingStyledBlackButton>
                </Grid>
            </Grid>
        </>
    );
};

export default Screen1;

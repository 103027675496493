import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { retrieveEventMetaData } from "../../firebase/FirestoreService";

// Firebase Imports
import firebaseApp from "../../firebase/FirebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";

const firestore = firebaseApp.firestore;

const EventMetrics = () => {
    // Pull params of URL
    let { eventId } = useParams(); // Grab the campaign/event ID from the current route

    // Set up state
    const [eventMetaData, setEventMetaData] = useState();
    const [eventMetrics, setEventMetrics] = useState();

    // Retrieve event meta data from firestore
    useEffect(() => {
        retrieveEventMetaData(eventId).then((data) => setEventMetaData(data));
    }, [eventId]);

    // Subscribe to updates for realtime metrics
    // Tutorial: https://youtu.be/F7t-n5c7JsE?t=3053
    useEffect(() => {
        const docRef = doc(
            firestore,
            `events/${eventId}/signup_data`,
            "--stats--"
        );
        const unsubscribe = onSnapshot(docRef, (snapshot) => {
            // console.log("Current data: ", snapshot.data());
            setEventMetrics(snapshot.data());
        });
        return () => {
            unsubscribe();
        };
    }, [eventId]);

    // useEffect(() => {
    //     if (eventMetrics) {
    //         console.log("Logging eventMetrics state variable: ", eventMetrics);
    //     }
    // }, [eventMetrics]);

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
            direction="column"
            sx={{
                width: "100vw",
                height: "100vh",
                // backgroundColor: "green",
            }}
        >
            <Typography variant="h3">
                <b>Electrifyze Event Metrics</b>
            </Typography>
            <Box m={1.0} />

            {eventMetaData && (
                <>
                    <Typography variant="h4">
                        {eventMetaData.eventName
                            ? eventMetaData.eventName
                            : eventId}
                    </Typography>
                    {/* <Typography variant="body1">
                        {new Date(
                            eventMetaData.eventDateTime.startDateTime.seconds *
                                1000
                        ).toLocaleDateString("en-US")}
                        {" - "}
                        {eventMetaData.eventLocation &&
                        eventMetaData.eventLocation.description
                            ? eventMetaData.eventLocation.description
                            : ""}
                    </Typography> */}
                </>
            )}

            <Box m={1.0} />
            {eventMetrics && (
                <>
                    <Typography variant="h6" color="red">
                        <b>Number of Sign-Ups:</b>
                    </Typography>
                    <Typography variant="h1" color="red">
                        <b>{eventMetrics.signupCount}</b>
                    </Typography>
                </>
            )

            }

            {/* Moves layout up to avoid buttons below the fold on mobile */}
            <Box m={5} />
        </Grid>
    );
};

export default EventMetrics;

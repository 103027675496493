import React from "react";
import NativeForms from "native-forms-react";
import { Typography, Grid } from "@mui/material";

// Assets
import CoachSmileGold from "../../static/images/Coach-Smile-Gold.svg";


const Screen3 = (props) => {
    const { handleFormSubmit, extraDataToAppend } = props;

    
    return (
        <>
            <Grid
                item
                container
                xs={12}
                sm={10}
                md={6}
                // sx={{ backgroundColor: "#ddd" }}
            >
                
                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 0, // Larger negative numbers move the layout up
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "90px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={CoachSmileGold}
                            style={{ height: "90px" }}
                            alt="Electrifyze Car Buying Coach"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            <b>Let's get to know you...</b>
                        </Typography>
                    </Grid>
                </Grid>

                <NativeForms
                    form="https://surveys.electrifyze.com/WZXc50jZmgnZzkEcL1Db/"
                    extraData={extraDataToAppend}
                    onBeforeSend={handleFormSubmit}
                />

            </Grid>
        </>
    );
};

export default Screen3;

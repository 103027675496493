import React from "react";

//Styles
import MuiDialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
    root: {
        display: "flex",
        alignItems: "center",
        margin: 0,
        padding: {
            sm: 1,
            md: 2
        },
        borderBottom: "1px solid gainsboro", //+ theme.palette.grey[400],
        height: 30,
        // [theme.breakpoints.down('lg')]: {
        //     height: 30,
        //     padding: 1,
        // },
    },
    dark: {
        backgroundColor: "black",
        color: "white",
    },
    smallBar: {
        padding: .8,
    },
    hideBorder: {
        borderBottom: "none",
    },
    closeButton: {
        position: "absolute",
        right: '16px',
        top: '16px',
        color: 'palette.grey[500]', // 'theme.palette.grey[500]',
        padding: .5,
    },
    smallButton: {
        padding: '2px',
    },
}

export const CustomDialogTitle = (props) => {
    const {
        children,
        onClose,
        small,
        hideBorder,
        dark,
        custom,
        ...other
    } = props;

    return (
        <MuiDialogTitle
            disableTypography
            sx={{
                ...styles.root,
                ...(dark && styles.dark),
                ...(hideBorder && styles.hideBorder),
                ...(small && styles.smallBar)
            }}
            {...other}
        >
            {custom ? (
                children
            ) : (
                <Typography variant="h6">
                    {children}&nbsp;
                </Typography>
            )}
            {onClose ? (
                <IconButton
                    sx={{
                        ...styles.closeButton,
                        ...(small && styles.smallButton)
                    }}
                    onClick={onClose}
                    size="large"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

import React from "react";
import { Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { CustomDialogTitle } from "../Styled Components/CustomDialogTitle";
import { QRCodeSVG } from "qrcode.react";

const QRShareDialog = (props) => {
    const { open, toggleDialog, urlToShare } = props;

    return (
        <Dialog
            open={open}
            onBackdropClick={toggleDialog}
            maxWidth="md"
            fullWidth={true}
            // onBackdropClick={toggleDialog}
        >
            <CustomDialogTitle onClose={toggleDialog}>
                Refer a Friend with Your Personalized QR Code
            </CustomDialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    spacing={2}
                    sx={{padding: 1}}
                >
                    <Grid item>
                        <Typography variant="body1">
                            Standing next to a friend who you want to refer?
                            Just have them scan this QR code with their phone.
                            You'll get credit when they sign up.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <QRCodeSVG value={urlToShare} level="M" />
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default QRShareDialog;

import React from "react";

import { styled, Button } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const StyledTaskButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    borderRadius: 10,
    padding: 15,
    paddingTop: 5,
    paddingBottom: 5,
    border: "2px solid black",
    color: "black",
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: 3,
        paddingBottom: 3,
    },
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    "&:disabled": {
        border: "2px solid rgba(0, 0, 0, 0.4)",
    },
}));

export const StyledGoldButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    color: "black",
    border: "1px solid black",
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    textTransform: "none",
}));

export const StyledBlackButton = styled(Button)(({ theme }) => ({
    backgroundColor: "black",
    color: "white",
    //height: 50,
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .7)",
    },
    padding: 15,
    paddingTop: 3,
    paddingBottom: 3,
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
}));

export const BouncingStyledBlackButton = styled(StyledBlackButton)(( { theme } ) => ({
    marginTop: 15,
    marginBottom: -7,
    "@keyframes bounce": {
        "0%, 33%, 7%, 17%, 27%": {
            webkitTransform: "translateY(0)",
            msTransform: "translateY(0)",
            transform: "translateY(0)",
        },
        "13%": {
            webkitTransform: "translateY(-30px)",
            msTransform: "translateY(-30px)",
            transform: "translateY(-30px)",
        },
        "20%": {
            webkitTransform: "translateY(-15px)",
            msTransform: "translateY(-15px)",
            transform: "translateY(-15px)",
        },
    },
    animation: `bounce 2.5s ${theme.transitions.easing.easeInOut} infinite`,
}));

export const StyledBlackOutlineButton = styled(Button)(({ theme }) => ({
    backgroundColor: "white",
    border: "2px solid black",
    color: "black",
    height: 50,
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    padding: 15,
    fontSize: 24,
    fontWeight: 600,
    textTransform: "none",
    [theme.breakpoints.down('xl')]: {
        fontSize: 20,
    },
    [theme.breakpoints.down('lg')]: {
        fontSize: 16,
    },
    [theme.breakpoints.down('lg')]: {
        paddingTop: 3,
        paddingBottom: 3,
    },
    [theme.breakpoints.down('md')]: {
        height: 'auto',
    },
}));

export const StyledBlackOutlineButtonSmall = styled(Button)(({ theme }) => ({
    backgroundColor: "white",
    border: "1px solid black",
    color: "black",
    borderRadius: 10,
    "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .3)",
    },
    textTransform: "none",
}));

const StyledArrowButton = styled(Button)(({ theme }) => ({
    backgroundColor: "rgba(255, 213, 0, 1)",
    minWidth: 50,
    height: 50,
    borderRadius: 25,
    "&:hover": {
        backgroundColor: "rgba(214, 179, 0, 1)",
    },
    padding: 0,
    fontSize: 24,
    fontWeight: 600,
    width: "auto",
}));

// export const StyledNextButton = ({ color, ...props }) => (
//     <StyledArrowButton {...props}>
//         <FontAwesomeIcon
//             icon={faArrowRight}
//             color={color ? color : "black"}
//             size={"lg"}
//         />
//     </StyledArrowButton>
// );

// export const StyledBackButton = ({ color, ...props }) => (
//     <StyledArrowButton {...props}>
//         <FontAwesomeIcon
//             icon={faArrowLeft}
//             color={color ? color : "black"}
//             size={"lg"}
//         />
//     </StyledArrowButton>
// );
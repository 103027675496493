import React from "react";
import {
    FacebookShareButton,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon,
} from "next-share";

import { Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Assets
import {
    faEnvelope,
    faCommentSms,
    faCopy,
    faQrcode,
} from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faFacebookF } from "@fortawesome/free-brands-svg-icons";

// =======================
// Styles
// =======================

// =======================
// Utility Functions
// =======================

// =======================
// Common Components
// =======================

const CustomShareButton = ({ bgColor, icon, text, minWidth = 135, handleClick }) => {
    return (
        <Button
            variant="contained"
            // color="primary"
            // onClick={handleEmailClick}
            // className={classes.bounce}
            sx={{
                backgroundColor: bgColor,
                minWidth: minWidth,
                alignItems: "center",
                fontSize: "1.2rem",
                fontWeight: 600,
            }}
            onClick={handleClick}
        >
            <FontAwesomeIcon
                icon={icon}
                style={{
                    marginTop: -1,
                    paddingRight: 10,
                    borderRight: "1px solid white",
                    marginRight: 10,
                }}
            />
            {text}
        </Button>
    );
};

// =======================
// Buttons for Export
// =======================
export const CustomFacebookShareButton = ({ urlToShare, quote, hashtag, logClick }) => {
    return (
        <FacebookShareButton url={urlToShare} quote={quote} hashtag={hashtag}>
            <CustomShareButton
                bgColor="#4267B2"
                icon={faFacebookF}
                text="Share"
                handleClick={logClick}
            />
        </FacebookShareButton>
    );
};

export const CustomTwitterShareButton = ({ urlToShare, title, logClick }) => {
    return (
        <TwitterShareButton url={urlToShare} title={title}>
            <CustomShareButton
                bgColor="#1b95e0"
                icon={faTwitter}
                text="Share"
                handleClick={logClick}
            />
        </TwitterShareButton>
    );
};

export const CustomEmailShareButton = ({ urlToShare, subject, body, logClick }) => {
    return (
        <EmailShareButton url={urlToShare} subject={subject} body={body}>
            <CustomShareButton 
                bgColor="black" 
                icon={faEnvelope} 
                text="Email"
                handleClick={logClick}
            />
        </EmailShareButton>
    );
};

export const CustomTextMessageButton = ({ handleClick }) => {
    return (
        <CustomShareButton
            bgColor="green"
            icon={faCommentSms}
            text="Text Msg"
            minWidth={170}
            handleClick={handleClick}
        />
    );
};

export const CustomCopyLinkButton = ({ handleClick }) => {
    return (
        <CustomShareButton
            bgColor="purple"
            icon={faCopy}
            text="Get Link"
            minWidth={160}
            handleClick={handleClick}
        />
    );
};

export const CustomQRCodeButton = ({ handleClick }) => {
    return (
        <CustomShareButton
            bgColor="olive"
            icon={faQrcode}
            text="QR Code"
            handleClick={handleClick}
        />
    );
};

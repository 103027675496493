import React, { useState } from "react";
import { Box, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { CustomDialogTitle } from "../Styled Components/CustomDialogTitle";
import { StyledGoldButton } from "../Styled Components/Buttons";

const TextMsgShareDialog = (props) => {
    const { open, toggleDialog, textMsgToShare } = props;

    const [copied, setCopied] = useState(false);

    const copyToClipboard = (type) => {
        if (/*@cc_on!@*/ false || !!document.documentMode) {
            //Internet explorer has unique method
            window.clipboardData.setData("Text", textMsgToShare);
        } else {
            navigator.clipboard.writeText(textMsgToShare);
        }

        setCopied(type);
    };

    const handleCopyClick = () => {
        copyToClipboard("url");
    };

    return (
        <Dialog
            open={open}
            onBackdropClick={toggleDialog}
            maxWidth="md"
            fullWidth={true}
            // onBackdropClick={toggleDialog}
        >
            <CustomDialogTitle onClose={toggleDialog}>
                Refer a Friend with a Text Message
            </CustomDialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction="row"
                    justifyContent="left"
                    spacing={2}
                    sx={{ padding: 1 }}
                >
                    <Grid item>
                        <Box m={1.0} />
                        <Typography variant="body1">
                            Here's a draft of a text message that you can copy
                            and share with your friends... It includes your
                            personalized link :)
                        </Typography>
                        <Box m={1.0} />
                    </Grid>
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: 10,
                            border: "1px solid #ccc",
                            borderRadius: 10,
                        }}
                    >
                        <Grid item container spacing={2} alignItems="center">
                            <Grid item xs container alignItems="center">
                                <Typography
                                    variant="body1"
                                    sx={{
                                        wordBreak: "break-all",
                                        fontSize: "0.70rem",
                                        // [theme.breakpoints.down("lg")]: {
                                        //     fontSize: ".75rem",
                                        // },
                                    }}
                                >
                                    {textMsgToShare}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StyledGoldButton
                                    // variant="contained"
                                    size="small"
                                    onClick={handleCopyClick}
                                >
                                    {/* copied === "url" ? 
                                <FontAwesomeIcon icon={faCheck} />
                            :
                                <FontAwesomeIcon icon={['far', 'copy']} />
                            */}
                                    {copied === "url" ? "Copied" : "Copy"}
                                </StyledGoldButton>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TextMsgShareDialog;

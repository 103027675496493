import React, { useState } from "react";
import { Box, Fade, Grid } from "@mui/material";
import { useSearchParams, useParams } from "react-router-dom";

// Firebase imports
import firebaseApp from "../../firebase/FirebaseConfig";
import { logEvent } from "firebase/analytics";

// Utility functions
import { writeSignupData } from "../../firebase/FirestoreService";
import isProductionEnv from "../../utils/isProductionEnv";

// Components
// 2022-lac-tasteofsoul
// import Screen1 from "../../event-content/2022-lac-tasteofsoul/Screen1";
// import Screen2 from "../../event-content/2022-lac-tasteofsoul/Screen2";
// import Screen3 from "../../event-content/2022-lac-tasteofsoul/Screen3";
// import Screen4 from "../../event-content/2022-lac-tasteofsoul/Screen4";
// import Screen5 from "../../event-content/2022-lac-tasteofsoul/Screen5";

// 2023-lac-fair
// import Screen1 from "../../event-content/2023-lac-fair/Screen1";
// import Screen2 from "../../event-content/2023-lac-fair/Screen2";
// import Screen3 from "../../event-content/2023-lac-fair/Screen3";
// import Screen4 from "../../event-content/2023-lac-fair/Screen4";
// import Screen5 from "../../event-content/2023-lac-fair/Screen5";

// 2023-lac-tasteofsoul
import Screen1 from "../../event-content/2023-lac-tasteofsoul/Screen1";
import Screen2 from "../../event-content/2023-lac-tasteofsoul/Screen2";
import Screen3 from "../../event-content/2023-lac-tasteofsoul/Screen3";
import Screen4 from "../../event-content/2023-lac-tasteofsoul/Screen4";
import Screen5 from "../../event-content/2023-lac-tasteofsoul/Screen5";

// Assets

const analytics = firebaseApp.analytics;

const EventLanding = () => {
    // Local App State
    const [currentStep, setCurrentStep] = useState(1); // Current step or screen number in a sequence of several screens
    const [formData, setFormData] = useState({
        completed: false,
        response: undefined,
    });

    // Pull params off of URL
    let { eventId } = useParams(); // Grab the campaign/event ID from the current route
    let [searchParams] = useSearchParams();
    // searchParams is a URLSearchParams object.
    // See https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    // let referral = searchParams.get("referral"); // Retrieve referral data from the URL params
    let utm_campaign = searchParams.get("utm_campaign"); // Event / campaign name
    let utm_source = searchParams.get("utm_source"); // Name of the piece of collateral we used
    let utm_medium = searchParams.get("utm_medium"); // Referrer medium
    let utm_content = searchParams.get("utm_content"); // Base-64 encoded ID of the referrer
    if (utm_content) {
        try {
            // Had to put this in a try/catch to prevent app from crashing if a bad utm_content string is provided in URL
            utm_content = atob(utm_content); // Decode the string if one is provided.
        } catch (e) {
            console.error(e.message);
        }
    }

    // Assembly of extra data that will get appended with NativeForms submission
    const extraDataToAppend = {
        utm_campaign: utm_campaign,
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_content: utm_content,
    };

    const handleButtonClick = () => {
        // console.log("Logging event: ", `${eventId}_screen${currentStep}_buttonclick`);
        if (isProductionEnv() === true) {
            logEvent(analytics, "select_content", {
                content_type: `${eventId}_screen${currentStep}_buttonclick`,
            });
        }

        setCurrentStep(currentStep + 1);
    };

    const handleFormSubmit = (event) => {
        // Save the form submission data to Firestore "signup_data" collection for the present event
        writeSignupData(eventId, event);

        // Save form submission data to local state
        setFormData({
            completed: true,
            response: event,
        });

        handleButtonClick(); // Advance to next screen
    };

    return (
        <Fade in={true} timeout={1000} key={currentStep}>
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    width: "100vw",
                    height: "100vh",
                    // backgroundColor: "green",
                }}
            >
                {currentStep === 1 && (
                    <Screen1 handleButtonClick={handleButtonClick} />
                )}
                {currentStep === 2 && (
                    <Screen2 handleButtonClick={handleButtonClick} />
                )}
                {currentStep === 3 && (
                    <Screen3
                        handleFormSubmit={handleFormSubmit}
                        extraDataToAppend={extraDataToAppend}
                    />
                )}
                {currentStep === 4 && (
                    <Screen4
                        handleButtonClick={handleButtonClick}
                        formData={formData}
                    />
                )}
                {currentStep === 5 && <Screen5 formData={formData} />}
                <Grid item xs={12}>
                    <Box m={5} />{" "}
                    {/* Moves layout up to avoid buttons below the fold on mobile */}
                </Grid>
            </Grid>
        </Fade>
    );
};

export default EventLanding;

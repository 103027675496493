import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

// Firebase imports
import firebaseApp from "../../firebase/FirebaseConfig";
import { logEvent } from "firebase/analytics";

// Assets
import CoachSmileGold from "../../static/images/Coach-Smile-Gold.svg";
import GiftCard250 from "../../static/images/GiftCard-250.png";
import {
    CustomCopyLinkButton,
    CustomEmailShareButton,
    CustomFacebookShareButton,
    CustomQRCodeButton,
    CustomTextMessageButton,
    CustomTwitterShareButton,
} from "../../components/Sharing/ReferShareButtons";
import QRShareDialog from "../../components/Sharing/QRShareDialog";
import LinkShareDialog from "../../components/Sharing/LinkShareDialog";
import isProductionEnv from "../../utils/isProductionEnv";
import TextMsgShareDialog from "../../components/Sharing/TextMsgShareDialog";

const analytics = firebaseApp.analytics;

// Template of email body to generate
const createEmailBody = (urlToShare, senderFirstName) => {
    return `Greetings –

I just found out about Electrifyze! I thought you might be interested too…

Electrifyze is LA County’s program to help create a fossil fuel free LA. They help people learn about electric cars and their benefits. And for people who are looking to get their next car, there’s a FREE car buying coach that helps save thousands off your next car.

Folks who sign up today get chances to win cool prizes too…

Check it out! Here’s my referral link so I can get credit for referring you :)
${urlToShare}

I signed up already - Hope you can join too...

Best wishes,
${senderFirstName}
`;
};

const createTextMsgBody = (urlToShare) => {
    return `Hey! I just found out about Electrifyze - they're working to create a fossil fuel free LA!

It's a really cool initiative, and they help people get an electric car.

Here's a link if you want to check it out:
${urlToShare}`;
};

// Screen5 Component
const Screen5 = (props) => {
    const { formData } = props;

    // Pull params off of URL
    let { eventId } = useParams(); // Grab the campaign/event ID from the current route

    // Edit the below values for each event
    const baseURLtoShare =
        "https://share.electrifyze.com/e/2023-lac-tasteofsoul.html";
    const utmCampaignVal = "2023-lac-tasteofsoul";
    const utmSourceVal = "referral";

    // Grab sign up data
    // console.log(formData);

    let userEmail;
    let userFirstName;

    try {
        userEmail = formData.response.formData.Email;
    } catch (error) {
        userEmail = "error@email.com";
    }
    const utmContentVal = btoa(userEmail);

    try {
        userFirstName = formData.response.formData["First Name"];
    } catch (error) {
        userFirstName = "";
    }

    // console.log("User email used for referrals: ", userEmail);
    // console.log("User first name used for referrals: ", userFirstName);
    // console.log("Encoded email for utm_content is: ", utmContentVal);

    const createShareUrl = (utmMediumVal) => {
        return `${baseURLtoShare}?utm_source=${utmSourceVal}&utm_medium=${utmMediumVal}&utm_campaign=${utmCampaignVal}&utm_content=${utmContentVal}`;
    };

    // console.log("url for QR: ", createShareUrl("QR"));
    // const urlToShare = baseURLtoShare;

    // Dialog states
    const [showQRDialog, setShowQRDialog] = useState(false);
    const [showLinkDialog, setShowLinkDialog] = useState(false);
    const [showTextMsgDialog, setShowTextMsgDialog] = useState(false);

    const logClick = (buttonName) => {
        // For GA Event tracking
        // Add GA event call here in the place of this console.log
        const GAEventName = `${eventId}_referral_button_clicked`;
        // console.log(`Logging event: ${GAEventName}`);
        if (isProductionEnv() === true) {
            logEvent(analytics, "share", {
                content_type: GAEventName,
                method: buttonName,
            });
        }
    };

    const toggleQRDialog = () => {
        if (showQRDialog === false) {
            // Prevents logging clicks when QR dialog is being closed.
            logClick("qr");
        }
        setShowQRDialog(!showQRDialog);
    };

    const toggleLinkDialog = () => {
        if (showLinkDialog === false) {
            // Prevents logging clicks when Link dialog is being closed.
            logClick("getlink");
        }
        setShowLinkDialog(!showLinkDialog);
    };

    const toggleTextMsgDialog = () => {
        if (showTextMsgDialog === false) {
            // Prevents logging clicks when Text dialog is being closed.
            logClick("text");
        }
        setShowTextMsgDialog(!showTextMsgDialog);
    };

    return (
        <>
            <Grid
                item
                container
                xs={12}
                sm={10}
                md={6}
                // sx={{ backgroundColor: "#ddd" }}
            >
                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 0, // Larger negative numbers move the layout up
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "90px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={CoachSmileGold}
                            style={{ height: "90px" }}
                            alt="Electrifyze Car Buying Coach"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            <b>Just tap to share</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 2,
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "143px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={GiftCard250}
                            style={{ height: "90px" }}
                            alt="$250 gift card"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Refer a friend for a chance to
                        </Typography>
                        <Typography variant="h5">
                            <b>Win a $250 Gift Card</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        marginTop: 2,
                        paddingX: 5,
                    }}
                >
                    <Typography variant="body1">
                        Each person that signs up from your shares gives you a
                        chance to win.
                    </Typography>
                </Grid>
                <Box m={1} />

                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    {[
                        <CustomFacebookShareButton
                            urlToShare={createShareUrl("facebook")}
                            quote={
                                "We’re creating a fossil fuel free community! Want to help? Sign up & get chances to win cool prizes. Buying a car? Go electric! Save up to $10k on your next car & money on expensive gas!"
                            }
                            hashtag={"#FossilFuelFreeLA"}
                            // onClick={() => console.log("Test FB Click.")}
                            logClick={() => logClick("facebook")}
                        />,
                        <CustomTwitterShareButton
                            urlToShare={createShareUrl("twitter")}
                            title={
                                "We’re creating a #FossilFuelFreeLA! Want to help? Sign up & get chances to win cool prizes. Buying a car? Go #EV! Save up to $10k on your next car & money on expensive gas!"
                            }
                            logClick={() => logClick("twitter")}
                        />,
                        <CustomEmailShareButton
                            urlToShare={""}
                            subject={"Help create a fossil fuel free LA"}
                            body={createEmailBody(
                                createShareUrl("email"),
                                userFirstName
                            )}
                            logClick={() => logClick("email")}
                        />,
                        <CustomTextMessageButton
                            handleClick={toggleTextMsgDialog}
                        />,
                        <CustomQRCodeButton handleClick={toggleQRDialog} />,
                        <CustomCopyLinkButton handleClick={toggleLinkDialog} />,
                    ].map((arrayItem, idx) => (
                        <Grid item key={idx}>
                            {arrayItem}
                        </Grid>
                    ))}
                </Grid>
            </Grid>

            {showQRDialog && (
                <QRShareDialog
                    open={true}
                    toggleDialog={toggleQRDialog}
                    urlToShare={createShareUrl("QR")}
                />
            )}
            {showLinkDialog && (
                <LinkShareDialog
                    open={true}
                    toggleDialog={toggleLinkDialog}
                    urlToShare={createShareUrl("link")}
                />
            )}
            {showTextMsgDialog && (
                <TextMsgShareDialog
                    open={true}
                    toggleDialog={toggleTextMsgDialog}
                    textMsgToShare={createTextMsgBody(createShareUrl("text"))}
                />
            )}
        </>
    );
};

export default Screen5;

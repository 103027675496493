import React from "react";
import { Routes, Route } from "react-router-dom";
import { Typography } from "@mui/material";

// import TrackRoute from "./pages/TrackRoute";
import EventLanding from "./pages/EventLanding/EventLanding";
import EventMetrics from "./pages/EventMetrics/EventMetrics";

function App() {
    return (
        <div className="App">
            {/* <TrackRoute /> */}
            <Routes>
                <Route
                    path="/"
                    element={<Typography variant="h6">Home</Typography>}
                />
                <Route 
                    path="/e/:eventId"
                    element={<EventLanding />}
                />
                <Route 
                    path="/metrics/:eventId"
                    element={<EventMetrics />}
                />
            </Routes>
        </div>
    );
}

export default App;

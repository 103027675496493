import React from "react";
import {
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { BouncingStyledBlackButton } from "../../components/Styled Components/Buttons";

// Assets
import CoachSmileGold from "../../static/images/Coach-Smile-Gold.svg";

const Screen2 = (props) => {
    const { handleButtonClick } = props;

    return (
        <>
            <Grid
                item
                container
                xs={12}
                sm={10}
                md={6}
                // sx={{ backgroundColor: "#ddd" }}
            >
                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 0, // Larger negative numbers move the layout up
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "90px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={CoachSmileGold}
                            style={{ height: "90px" }}
                            alt="Electrifyze Car Buying Coach"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            <b>Electrifyze helps you...</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    sx={{
                        marginTop: 2,
                        paddingX: 2,
                    }}
                >
                    <Grid
                        container
                        item
                        justifyContent="left"
                        sx={{
                            marginTop: 1,
                            paddingLeft: 3,
                        }}
                    >
                        <Typography align="left" variant="body2">
                            If you're thinking about your next car:
                        </Typography>
                    </Grid>
                    <List dense={false}>
                        <ListItem>
                            <ListItemIcon>
                                <CheckRoundedIcon
                                    sx={{ color: "black" }}
                                    fontSize="large"
                                />
                            </ListItemIcon>
                            <Typography variant="h5">
                                <b>Save money on expensive gas</b>
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckRoundedIcon
                                    sx={{ color: "black" }}
                                    fontSize="large"
                                />
                            </ListItemIcon>
                            <Typography variant="h5">
                                <b>Save up to $10k on your next car</b>
                            </Typography>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <CheckRoundedIcon
                                    sx={{ color: "black" }}
                                    fontSize="large"
                                />
                            </ListItemIcon>
                            <Grid container direction="column">
                                <Typography variant="h5">
                                    <b>Get help from a FREE car buying coach</b>
                                </Typography>
                                <Typography variant="body1">
                                    Find the best deal with less hassle
                                </Typography>
                            </Grid>
                        </ListItem>
                    </List>

                    <Grid
                        container
                        item
                        justifyContent="left"
                        sx={{
                            marginTop: 1,
                            paddingLeft: 3,
                        }}
                    >
                        <Typography align="left" variant="body2">
                            If you want to create a fossil fuel free community:
                        </Typography>
                    </Grid>
                    <List dense={false}>
                        <ListItem>
                            <ListItemIcon>
                                <CheckRoundedIcon
                                    sx={{ color: "black" }}
                                    fontSize="large"
                                />
                            </ListItemIcon>
                            <Typography variant="h5">
                                <b>
                                    Become an EV Champion to amplify your impact
                                </b>
                            </Typography>
                        </ListItem>
                    </List>
                </Grid>

                <Grid container item justifyContent="center">
                    <BouncingStyledBlackButton onClick={handleButtonClick}>
                        <Typography
                            variant="h4"
                            sx={{ marginY: 1, marginX: 10 }}
                        >
                            <b>Onwards...</b>
                        </Typography>
                    </BouncingStyledBlackButton>
                </Grid>
            </Grid>
        </>
    );
};

export default Screen2;

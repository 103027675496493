import React from "react";
import {
    Box,
    Grid,
    Typography,
} from "@mui/material";
import { BouncingStyledBlackButton } from "../../components/Styled Components/Buttons";

// Assets
import CoachThumbsUpBeaming from "../../static/images/Coach-ThumbsUp-Beaming-Gold.svg";
import GiftCard250 from "../../static/images/GiftCard-250.png";

const Screen4 = (props) => {
    const { handleButtonClick, formData } = props;
    let formResponses;
    if (typeof formData.response != "undefined") {
        formResponses = formData.response.formData;
    }

    return (
        <>
            <Grid
                item
                container
                xs={12}
                sm={10}
                md={6}
                // sx={{ backgroundColor: "#ddd" }}
            >
                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 0, // Larger negative numbers move the layout up
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "90px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={CoachThumbsUpBeaming}
                            style={{ height: "90px" }}
                            alt="Electrifyze Car Buying Coach"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h5">
                            <b>Hi, { formResponses && formResponses["First Name"] ? formResponses["First Name"] : "there" }</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    sx={{
                        paddingX: 7,
                    }}
                >
                    <Typography align="center" variant="h4">
                        <b>You're all signed up!</b>
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    // justifyContent="center"
                    sx={{
                        marginTop: 1,
                        paddingX: 5,
                    }}
                >
                    <Typography align="left" variant="body1">
                        We'll be in touch soon to share more about Electrifyze.
                    </Typography>
                    <Box m={2} />
                    <Typography align="left" variant="body1">
                        In the meantime, we have a <b>special bonus prize</b>{" "}
                        for folks who <b>help spread the word</b> that LA is
                        going fossil fuel free...
                    </Typography>
                </Grid>

                <Grid
                    container
                    item
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        // backgroundColor: "red",
                        marginTop: 2,
                        paddingX: 2,
                    }}
                >
                    <Grid
                        item
                        sx={{
                            width: "143px",
                            marginRight: 1,
                        }}
                    >
                        <img
                            src={GiftCard250}
                            style={{ height: "90px" }}
                            alt="$250 gift card"
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1">
                            Refer a friend for a chance to
                        </Typography>
                        <Typography variant="h5">
                            <b>Win a $250 Gift Card</b>
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container item justifyContent="center" sx={{marginTop: 1}}>
                    <BouncingStyledBlackButton onClick={handleButtonClick}>
                        <Grid
                            container
                            direction="column"
                            sx={{ marginY: 1, marginX: 8 }}
                        >
                            <Typography variant="h4">
                                <b>Refer a Friend</b>
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{ marginTop: -0.5 }}
                            >
                                and earn chances to win
                            </Typography>
                        </Grid>
                    </BouncingStyledBlackButton>
                </Grid>
            </Grid>
        </>
    );
};

export default Screen4;

// Detects whether the user is in a production environment or not
// Used throughout the app in conditional statements that should only be run in production environments

// Set this override to true if we want all isProduction conditional calls throughout the app (e.g. for Analytics logging) to run even while in a development environment
const overrideIsProduction = false; // This shoud usually be set to "false" unless we are debugging.

// NOTE: Need to ensure we include different sub-domains, e.g. www vs. no www....
const productionHostnames = [
    'events.electrifyze.com',
];

// Returns true if the user is in a production environment
const isProductionEnv = () => {
    // Skip the production environment check if the override is activated
    if (overrideIsProduction) {
        console.warn('isProduction override has been turned on.');
        return true;
    };

    let inAProductionEnvironment = false;
    if (productionHostnames.indexOf(window.location.hostname) !== -1) { // This expression evaluates to TRUE if the user's current hostname being viewed is contained in the 'productionHostnames' variable.
        inAProductionEnvironment = true;
    }

    return inAProductionEnvironment;
};

export default isProductionEnv;


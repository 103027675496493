import { createTheme, responsiveFontSizes } from "@mui/material";

let generateTheme = createTheme({
    palette: {

    }
});

// Make typography smaller on small screens
// See: https://mui.com/material-ui/customization/theming/#responsivefontsizes-theme-options-theme
generateTheme = responsiveFontSizes(generateTheme);

export const theme = generateTheme;